body {
  font-family: 'Arial', sans-serif;
}

.container {
  margin-top: 20px;
}

.portfolio-menu ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.portfolio-menu ul li {
  margin: 0 10px;
  cursor: pointer;
}

.portfolio-menu ul li.active {
  background-color: #007bff;
  color: white;
}

#not-found {
  display: none;
  color: red;
}

.download-link {
  display: block;
  text-align: center;
}
