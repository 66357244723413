.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #343a40;
    color: white;
  }
  
  .navbar-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
    margin-left: 15px !important;
  }
  
  .navbar-toggle i {
    margin-right: 5px;
  }
  
  .navbar-brand a {
    color: white;
    text-decoration: none;
    font-size: 24px; /* Adjust font size as needed */
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    margin-left: 15px;
    border-radius: 20px; /* Make the button rounded */
    padding: 8px 15px; /* Adjust padding for better appearance */
  }
  .btn-dark{
    margin-right: 15px;
  }
  
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
    }
  }
  