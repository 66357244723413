/* src/components/Footer.css */

.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer .social-icons a {
    margin-right: 10px;
    font-size: 1.5em;
    color: #fff;
  }
  
  .footer .social-icons a:hover {
    color: #ddd;
  }
  
  .footer .company-info, 
  .footer .learn-more, 
  .footer .need-help {
    margin-bottom: 20px;
  }
  
  .footer .contact-info p {
    margin: 0;
  }
  
  .footer .contact-info i {
    margin-right: 10px;
  }
  
  .footer .copyright {
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid #444;
  }
  
  /* Responsive Styles */
  @media (max-width: 767px) {
    .footer .row {
      flex-direction: column;
      text-align: center;
    }
  
    .footer .col-md-3 {
      margin-bottom: 20px;
    }
  
    .footer .social-icons {
      margin-top: 10px;
    }
  
    .footer .social-icons a {
      margin: 0 5px;
    }
  }
  
  @media (max-width: 575px) {
    .footer .contact-info p,
    .footer .company-info ul,
    .footer .learn-more ul,
    .footer .need-help ul {
      font-size: 0.9em;
    }
  }
  
  