.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #343a40;
    color: white;
    transition: left 0.3s ease;
    z-index: 1000;
    padding: 20px;
  }
  
  .sidebar-close {
    cursor: pointer;
    text-align: right;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 10px 0;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
  }
  