
.container {
    padding-top: 20px;
  }
  
  .portfolio-menu {
    text-align: center;
  }
  
  .portfolio-menu ul {
    padding: 0;
    list-style: none;
  }
  
  .portfolio-menu ul li {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .portfolio-menu ul li.active {
    font-weight: bold;
  }
  
  #portfolio-items {
    display: flex;
    flex-wrap: wrap;
  }
  
  .item {
    margin-bottom: 20px;
  }
  
  .item img {
    max-width: 100%;
    border-radius: 5px;
  }
  
  .input-group {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .input-group-text {
    background-color: #ddd;
    border: none;
  }
  .fa-search{
    font-size: 30px;
    cursor: pointer;
  }
  
 /* Initially hide the download button */
.item .download-link {
    display: none;
  }
  .download-link{
    background-color: gray !important;
  }
  
  /* Show the download button when the item is hovered */
  .item:hover .download-link {
    display: block;
    position: absolute; /* Optional: for better positioning */
    bottom: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
  }
  
  
  